@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');


.main-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.left-login {
  width: 65vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("/public/background.jpeg");
  background-position: center;
  background-size: cover;
  opacity: 0.6;
}

.texto-logo {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 800;
}


.right-login {
  width: 35vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  /* box-shadow: 0px 10px 40px #00000056; */
}

.card-login {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 35px;
  background-color: #fff;
  border-radius: 20px;
  /* box-shadow: 0px 10px 40px #00000056; */
}

.card-login h1 {
  color: #000000;
  font-weight: 800;
  margin: 0;
}

.logo-image {
  width: 80%;
}

.logo-login {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  flex-direction: column;
}

.textfield {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.textfield input {
  width: 100%;
  border:#f5f5f5 solid 2px;
  border-radius: 6px;
  padding: 8px;
  /* background: #f5f5f5; */
  font-size: 12pt;
  outline: none;
  box-sizing: border-box;
}
.textfield input:focus {
  box-shadow: 0px 1px 4px 1px #00000056;
}

.textfield label {
  margin-top: 15px;
  color: gray;
  font-weight: 400;
  margin-bottom: 10px;
}

.textfield input::placeholder {
  color: black
}

.btn-login {
  width: 100%;
  padding: 6px 0px;
  margin: 25px;
  border: none;
  border-radius: 8px;
  outline: none;
  /* text-transform: uppercase; */
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
  background-color: #026eb6;
  cursor: pointer;
  box-shadow: 0px 10px 40px -12px #00000056;
  /* transition: 0.1s; */
}

@media only screen and (min-width: 1600px) {
  .card-login {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {

  .main-login {
    flex-direction: column;
  }

  .left-login {
    width: 100%;
    height: auto;
    display: none;
  }

  .right-login {
    width: 100%;
    height: auto;
  }

  .card-login {
    width: 100%;
  }

}