@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

body {
  background-color: #f5f5f5;
  font-family: 'Ubuntu', sans-serif;
}

.menu-elevado {
  transition: 0.3s;
}

.menu-elevado:hover {
  transform: translateY(-2px);
  color: white;
}

.titulo-topbar {
  font-family: 'Ubuntu', sans-serif;
}

.modal-75 {
  min-width: 75%;
}

.modal-60 {
  min-width: 60%;
}

.modal-50 {
  min-width: 50%;
}

.modal-30 {
  min-width: 30%;
}

.item-busca-cliente {
  background-color: white;
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
}

.item-busca-cliente:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}


.item-busca-proposta {
  background-color: white;
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  z-index: -1;
}

.item-busca-proposta:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.icone-menu-propostas {
  display: flex;
  flex-direction: column;
  /* background-color: pink; */
  justify-content: center;
  align-items: center;
  min-width: 72px;
  max-width: 140px;
  max-height: 72px;
  cursor: pointer;
  transition: 0.3s;
}

.icone-menu-propostas:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.card-interno {
  cursor: pointer;
  width: 15rem;
  transition: 0.3s;
}

.card-interno:hover {
  transform: scale(1.15);

}

.card-plano {
  margin-left: 15px;
  margin-right: 15px;
}

.item-plano {
  background-color: white;
  width: 100%;
  height: 60px;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}

.item-plano:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
}

.item-plano-rodape-mes {
  background-color: gainsboro;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}

.item-plano-rodape-mes:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
  height: 120px;
}

.item-plano-rodape-ano {
  background-color: gainsboro;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  border-bottom: 2px solid #f5f5f5;
  border-left: 2px solid #f5f5f5;
  border-right: 2px solid #f5f5f5;
}

.item-plano-rodape-ano:hover {
  background-color: #f5f5f5;
  transform: translateY(-2px);
  height: 120px;
}

.item-plano-rodape-texto {
  padding-left: 15px;
}

.item-plano-rodape-preco {
  padding-right: 5px;
}

.item-plano-rodape-botao-ano {
  font-weight: 500;
  color: green;
  display: none;
  margin-top: 5px;
}

.item-plano-rodape-botao-mes {
  font-weight: 500;
  color: green;
  display: none;
  margin-top: 5px;
}

.item-plano-rodape-mes:hover .item-plano-rodape-botao-mes {
  display: block;
}

.item-plano-rodape-ano:hover .item-plano-rodape-botao-ano {
  display: block;
}

.botao-proposta-cliente {
  border-radius: 10px;
  padding: 15px;
  margin-right: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  width: 200px;

}

.botao-proposta-cliente:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.proposta-header {
  height: 200px;
  display: flex;
  box-shadow: 5px 3px 3px grey;
  z-index: 10;
  position: relative;
}

.proposta-logo {
  max-width: 30%;
  max-height: 180px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.proposta-coluna-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10%;
}

.proposta-header-itens {
  background-color: gainsboro;
  width: 100%;
  height: 40px;
  border-start-start-radius: 10px;
  border-start-end-radius: 10px;
  display: flex;
}

.proposta-header-itens :nth-child(1) {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px
}


.proposta-header-itens :nth-child(2) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;

}

.proposta-header-itens :nth-child(3) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}

.proposta-header-itens :nth-child(4) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}

.proposta-itens {
  background-color: white;
  width: 100%;
  display: flex
}

.proposta-itens :nth-child(1) {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px;
  text-transform: capitalize;
}

.proposta-itens :nth-child(2) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}

.proposta-itens :nth-child(3) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}

.proposta-itens :nth-child(4) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
}

.proposta-footer-itens {
  background-color: gainsboro;
  width: 100%;
  height: 40px;
  border-end-start-radius: 10px;
  border-end-end-radius: 10px;
  display: flex;
}

.proposta-footer-itens :nth-child(1) {
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 15px
}

.proposta-footer-itens :nth-child(2) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}

.proposta-footer-itens :nth-child(3) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}

.proposta-footer-itens :nth-child(4) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}
.proposta-footer-itens :nth-child(4) {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center
}

@media screen and (max-width: 600px) {

  .proposta-barra-superior-branca {
    display: none;
  }

  .proposta-logo {
    display: none;
  }

  .botao-proposta-cliente {
    width: 200px;
    margin: 10px;
  }

  .proposta-header {
    padding-top: 30px;
  }

  .proposta-header-itens {
    font-size: 8pt;
  }

  .proposta-header-itens :nth-child(1) {
    padding-left: 5px;
    width: 40%;
  }


  .proposta-header-itens :nth-child(2) {
    width: 15%;
    justify-content: center;

  }

  .proposta-header-itens :nth-child(3) {
    width: 25%;
    justify-content: flex-end;
  }

  .proposta-header-itens :nth-child(4) {
    width: 20%;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .proposta-itens {
    font-size: 8pt;
  }

  .proposta-itens :nth-child(1) {
    width: 40%;
    padding-left: 5px;
    text-transform: capitalize;
  }

  .proposta-itens :nth-child(2) {
    width: 15%;
    justify-content: center
  }

  .proposta-itens :nth-child(3) {
    width: 25%;
    justify-content: flex-end
  }

  .proposta-itens :nth-child(4) {
    width: 20%;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .proposta-footer-itens {
    font-size: 8pt;
  }

  .proposta-footer-itens :nth-child(1) {
    width: 40%;
    padding-left: 5px;
    text-transform: capitalize;
  }

  .proposta-footer-itens :nth-child(2) {
    width: 10%;
    justify-content: center;
  }

  .proposta-footer-itens :nth-child(3) {
    width: 20%;
    justify-content: flex-end
  }

  .proposta-footer-itens :nth-child(4) {
    width: 20%;
    justify-content: flex-end;
    padding-right: 10px;
  }
}